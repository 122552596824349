import axios from 'axios'
import Vue from 'vue'
import dayjs from 'dayjs'
import { saveToUrl, getProjectMediaSourceForUpload, uploadFileThroughAdam, uploadFile } from '../services/uploads'
import {
  presignedUrlExistingProject,
  getPresignedSpreadsheetUrl,
  importErrorPresignedUrl,
  uploadSpreadsheetFile,
  startImportProcess,
  getStatusImportExport,
  postWorkflowProject,
  getError,
  getMappings,
  postMappings
} from '../services/workflow'
import { MEDIA_SOURCE_ID } from '../common/constants'
import {
  supportedImgExtensions,
  supportedJstorExtensions,
  supportedArtstorExtensions,
  supportedExtensions,
  supportedArtstorAjiExtensions
} from '../common/uploads'
import { pushToDatalayer } from '@/common/gtagmanager'
import userState from './userState'
import poll from '@/common/poll'

let uploadedFileInfo = {}

const defaultUploads = () => ({
  files: [],
  supportedImgExtensions,
  supportedExtensions,
  supportedJstorExtensions,
  supportedArtstorExtensions,
  supportedArtstorAjiExtensions,
  tokens: [],
  cancelSources: [],
  uploadStatuses: {},
  progress: 'none',
  promise: null,
  promiseResolve: null,
  beginUpload: null,
  endUpload: null,
  uploadTime: 0,
  uploadParams: {},
  groupEnabled: false,
  background: false,
  currentUploadingFileIndex: null,
  cancelledFiles: [],
  failedFiles: [],
  retryingFiles: []
})

const uploads = {
  namespaced: true,
  state: defaultUploads(),
  mutations: {
    START_UPLOAD(state, { files, params, groupEnabled }) {
      Object.assign(state, defaultUploads())
      state.files = files
      state.progress = 'uploading'
      state.promise = new Promise(res => (state.promiseResolve = res))
      state.beginUpload = new Date()
      state.uploadParams = params
      state.groupEnabled = groupEnabled
    },
    FINISH_UPLOAD(state, results) {
      state.progress = 'finished'
      state.endUpload = new Date()
      const end = dayjs(state.endUpload)
      const start = dayjs(state.beginUpload)
      state.uploadTime = Number((end.diff(start) / 1000).toFixed(2))
      state.promiseResolve(results)
    },
    SET_CANCEL_SOURCES(state, cancelSources) {
      state.cancelSources = cancelSources
    },
    REMOVE_CANCEL_SOURCE(state, index) {
      state.cancelSources.slice(index, 1)
    },
    FETCHED_TOKENS(state, tokens) {
      state.tokens = tokens
    },
    SET_BACKGROUND(state) {
      state.background = true
    },
    SET_CURRENT_UPLOADING_FILE_INDEX(state, index) {
      state.currentUploadingFileIndex = index
    },
    SET_CANCELLED_FILES(state, fileName) {
      state.cancelledFiles.push(fileName)
    },
    SET_FAILED_FILES(state, fileName) {
      state.failedFiles.push(fileName)
    },
    SET_RETRYING_FILES(state, fileName) {
      state.retryingFiles.push(fileName)
    },
    REMOVE_RETRYING_FILES(state, fileName) {
      const index = state.retryingFiles.indexOf(fileName)
      state.retryingFiles.splice(index, 1)
    },
    RESET_STATE(state) {
      Object.assign(state, defaultUploads())
    },
    SET_UPLOAD_STATUS(state, { fileName, progress }) {
      if (Number.isFinite(progress)) {
        Vue.set(state.uploadStatuses, fileName, progress)
      }
    }
  },
  actions: {
    _getJobDetails(context, options) {
      const { results, projectId } = options
      let failureCount = 0
      let cancelCount = 0
      const details = results.reduce((arr, result) => {
        if (result.res.data.cancelled === true) {
          cancelCount++
        } else if (result.res.data.success === false) {
          failureCount++
          let error = result.res.data.error
          let errorType = undefined
          if (error.response && error.response.data) {
            error = error.response.data
            errorType = error.error_type
          }
          arr.push({ name: result.file.name, error: error.message, errorType })
        }
        return arr
      }, [])

      return {
        projectId,
        uploadCount: results.length,
        failureCount,
        successCount: results.length - failureCount - cancelCount,
        cancelCount,
        date: new Date().toISOString(),
        details
      }
    },
    async _saveJobDetails(context, options) {
      const jobDetails = await context.dispatch('_getJobDetails', options)
      await context.dispatch('userState/saveUploadState', jobDetails, { root: true })
    },
    async _initializeGAEvents(context, { compounded } = {}) {
      const events = {}
      const successKey = `${compounded ? '' : 'Non-'}Compounded Success`
      const failureKey = `${compounded ? '' : 'Non-'}Compounded Failure`
      const cancelKey = `${compounded ? '' : 'Non-'}Compounded Cancel`
      const uploadKey = 'Upload Count'
      events[successKey] = { 'First Attempt': 0, Retried: 0 }
      events[failureKey] = 0
      events[cancelKey] = 0
      events[uploadKey] = 0
      return { events, successKey, failureKey, cancelKey, uploadKey }
    },
    async _sendGTMDataLayers(context, { events }) {
      const sendEvent = (event, label, value) => {
        if (value > 0) {
          pushToDatalayer('Uploads', event, label, value)
        }
      }
      for (const event in events) {
        if (!events.hasOwnProperty(event)) continue
        if (typeof events[event] === 'object') {
          sendEvent(event, 'First Attempt', events[event]['First Attempt'])
          sendEvent(event, 'Retried', events[event]['Retried'])
        } else {
          sendEvent(event, '', events[event])
        }
      }
    },
    /**
     * upload for asset grid or non-compound object
     * @param {object} context
     * @param {object} options
     */
    async uploadFiles(context, options) {
      let files = [...options.files]
      const { setId, projectId, filterId, projectName, upload_type } = options
      context.commit('START_UPLOAD', { files, params: { setId, projectId, filterId }, groupEnabled: false })

      const { type, mode, assetType, mediaId, onProgress, paramId, assetId, ocrEnabled } = options

      const mediaSource = await getProjectMediaSourceForUpload(projectId)

      const sourceType = mediaSource.data.tag
      const sourceId = mediaSource.data.id
      const results = []
      const cancelSources = []
      const retryAttempts = {}
      const { events, successKey, failureKey, cancelKey, uploadKey } = await context.dispatch('_initializeGAEvents')
      events[uploadKey] = files.length

      // pre-generate the cancel tokens
      for (let i = 0; i < files.length; i++) {
        cancelSources[i] = new AbortController()
      }
      context.commit('SET_CANCEL_SOURCES', cancelSources)

      const retryCache = {}

      // For each file...
      for (let i = 0; i < files.length; i++) {
        const start = dayjs(new Date())

        let file = files[i]
        context.commit('SET_CURRENT_UPLOADING_FILE_INDEX', i)
        const startTime = start.format('YYYY-MM-DDTHH:mm:ss:SSS')
        uploadedFileInfo = {
          fileName: file.name,
          fileSize: file.size,
          startTime,
          success: false
        }
        const progressCallback = (progressPortion, progressBase, progress) => {
          const finalProgress = progress * progressPortion + progressBase
          context.commit('SET_UPLOAD_STATUS', { fileName: file.name, progress: finalProgress })
          onProgress && onProgress(file.name, finalProgress)
        }

        try {
          let signal = cancelSources[i].signal
          let representationId = retryCache[file.name]
          const ocr = file.type.startsWith('image') && ocrEnabled === true

          if (!representationId) {
            if (mediaSource.data.type_id === MEDIA_SOURCE_ID) {
              const res = await uploadFile(
                mediaSource.data.stor_url,
                file,
                progressCallback.bind(this, 0.9, 0),
                signal,
                ocr
              )

              representationId = res.data.id
            } else {
              const res = await uploadFileThroughAdam(projectId, file, progressCallback.bind(this, 0.9, 0), signal, ocr)

              representationId = res.data.imageId
            }
            retryCache[file.name] = representationId
          }

          if (!representationId) {
            break
          }

          let url, formData

          // 4.a) Update the projects assets for new record creation
          if (mode === 'create') {
            formData = {
              representation_id: `${sourceType}:${representationId}`,
              filename: file.name
            }

            if (type === 'asset') {
              url = `/${assetType}/${paramId}/assets`
            } else {
              url = `/assets/${assetId}/media-objects`
            }
          } else if (mode === 'replace') {
            formData = {
              'replace-media-field': representationId,
              sourceType,
              sourceId,
              file_name: file.name
            }
            if (type === 'asset') {
              url = `/assets/${assetId}/representation`
            } else {
              url = `/media-objects/${mediaId}/representation`
            }
          }

          const res = await poll(
            async () => {
              return await saveToUrl(url, formData, progressCallback.bind(this, 0.1, 90), null)
            },
            res => res.data.success === true,
            {
              interval: 1000,
              timeout: 30000
            },
            true
          )

          const end = dayjs(new Date())
          const uploadTime = Number((end.diff(start) / 1000).toFixed(2))
          results.push({ file, res, uploadTime, startTime })
          const uploadedFile = {
            fileName: file.name,
            fileSize: file.size,
            success: res.data.success,
            startTime,
            uploadTime
          }

          userState.actions.captainsLog(context, {
            eventtype: 'forum_media_uploaded_individual_files',
            upload_type,
            project_id: projectId,
            project_name: projectName,
            message: 'individual file uploaded status',
            uploadedFile
          })

          events[successKey][retryAttempts[i] ? 'Retried' : 'First Attempt']++
        } catch (error) {
          if (error.name === 'CanceledError') {
            context.commit('SET_CANCELLED_FILES', file.name)
            events[cancelKey]++
            results.push({ file, res: { data: { cancelled: true, error } } })
            continue
          }
          if (!retryAttempts[i]) {
            retryAttempts[i] = true
            context.commit('SET_RETRYING_FILES', file.name)
            i--
            continue
          }
          context.commit('REMOVE_RETRYING_FILES', file.name)
          context.commit('SET_FAILED_FILES', file.name)
          events[failureKey]++
          console.error(error)
          results.push({ file, res: { data: { success: false, error } } })
          userState.actions.captainsLog(context, {
            eventtype: 'forum_media_uploaded_individual_files',
            upload_type,
            project_id: projectId,
            project_name: projectName,
            message: 'individual file uploaded status',
            uploadedFile: { ...uploadedFileInfo }
          })
        }
      }
      await context.dispatch('_sendGTMDataLayers', { events })
      await context.dispatch('_saveJobDetails', { results, projectId })

      context.commit('FINISH_UPLOAD', results)

      return results
    },
    async importErrors(context, workflow) {
      const token = context.rootState.user.token
      return getError(token, workflow)
    },
    async fetchPresignedUrl(context, { file }) {
      const token = context.rootState.user.token
      return getPresignedSpreadsheetUrl(token, file)
    },
    async fetchPresignedUrlExistingProject(context, options) {
      const { project_id, file } = options
      const token = context.rootState.user.token
      return presignedUrlExistingProject({ token, project_id, file })
    },
    async fetchImportErrorPresignedUrl(context, options) {
      const { job_id } = options
      const token = context.rootState.user.token
      return importErrorPresignedUrl({ token, job_id })
    },
    async uploadSpreadsheet(context, { url, file, progress }) {
      return uploadSpreadsheetFile(url, file, progress)
    },
    async startImport(context, { key, workflow }) {
      const token = context.rootState.user.token
      return startImportProcess(token, { key, workflow })
    },
    async fetchImportStatus(context, { workflow, existingProject }) {
      const token = context.rootState.user.token
      return getStatusImportExport(token, workflow, existingProject)
    },
    async fetchMappings(context, workflow) {
      const token = context.rootState.user.token
      return getMappings(token, workflow)
    },
    async sendMappings(context, { workflow, mappingData }) {
      const token = context.rootState.user.token
      return postMappings(token, workflow, mappingData)
    },
    async createWorkflowProject(context, params) {
      const token = context.rootState.user.token
      const { workflow, ...data } = params
      return postWorkflowProject(token, workflow, data)
    },
    async uploadCompoundFiles(context, options) {
      let files = [...options.files]
      const { setId, projectId, filterId } = options
      context.commit('START_UPLOAD', { files, params: { setId, projectId, filterId }, groupEnabled: true })

      const { assetType, paramId, onProgress, ocrEnabled } = options

      const mediaSource = await getProjectMediaSourceForUpload(projectId)

      const sourceType = mediaSource.data.tag

      const groups = { ...options.groups }
      const results = []

      const { events, successKey, failureKey, cancelKey, uploadKey } = await context.dispatch('_initializeGAEvents', {
        compounded: true
      })
      const retryAttempts = {}
      for (const key in groups) {
        let files = [...groups[key]]
        const cancelSources = []

        // pre-generate the cancel tokens
        for (let i = 0; i < files.length; i++) {
          cancelSources[i] = new AbortController()
        }
        context.commit('SET_CANCEL_SOURCES', cancelSources)

        // For each file...
        let assetId = null
        for (let i = 0; i < files.length; i++) {
          context.commit('SET_CURRENT_UPLOADING_FILE_INDEX', i)
          context.commit('SET_CURRENT_CANCEL_SOURCE', cancelSources[i])
          const retryKey = `${key}-${i}`
          const type = i === 0 || key === '__individual__' ? 'asset' : 'media'

          const progressCallback = (progressPortion, progressBase, progress) => {
            const finalProgress = progress * progressPortion + progressBase
            context.commit('SET_UPLOAD_STATUS', { fileName: files[i].name, progress: finalProgress })
            onProgress && onProgress(files[i].name, finalProgress)
          }

          let file = files[i]

          try {
            if (type === 'media' && !assetId) {
              throw new Error('First file in group failed to upload')
            }

            let token = cancelSources[i].signal

            const ocr = file.type.startsWith('image') && ocrEnabled === true
            const storResponse = await uploadFile(
              mediaSource.data.stor_url,
              file,
              progressCallback.bind(this, 0.9, 0),
              token,
              ocr
            )

            const representationId = storResponse.data.id

            if (!representationId) {
              throw new Error('Stor response missing representation id')
            }

            // 4.a) Update the projects assets for new record creation
            const formData = {
              representation_id: `${sourceType}:${representationId}`,
              filename: files[i].name
            }

            let url
            if (type === 'asset') {
              url = `/${assetType}/${paramId}/assets`
            } else {
              url = `/assets/${assetId}/media-objects`
            }

            const res = await saveToUrl(url, formData, progressCallback.bind(this, 0.1, 90), null)
            if (type === 'asset') {
              assetId = res.data.assets[0].id
            }

            results.push({ file, res, type })
            events[successKey][retryAttempts[retryKey] ? 'Retried' : 'First Attempt']++
          } catch (error) {
            if (error.toString() === 'Cancel') {
              context.commit('SET_CANCELLED_FILES', file.name)
              events[cancelKey]++
              results.push({ file, res: { data: { cancelled: true, error } } })
              continue
            }
            if (!retryAttempts[retryKey]) {
              retryAttempts[retryKey] = true
              i--
              continue
            }
            events[failureKey]++
            context.commit('SET_FAILED_FILES', file.name)
            console.error(error)
            results.push({ file, res: { data: { success: false, error } } })
          }
        }
      }
      events[uploadKey] = results.length
      await context.dispatch('_sendGTMDataLayers', { events })
      await context.dispatch('_saveJobDetails', { results, projectId })

      context.commit('FINISH_UPLOAD', results)
      return results
    },
    clearCancelToken(context, index) {
      context.commit('REMOVE_CANCEL_SOURCE', index)
    },
    fetchMediaSources(context, options) {
      const { projectId } = options
      return axios.get(`/projects/${projectId}/media-sources`)
    },
    verifyDrs(context, options) {
      const { sourceId, identifier } = options
      return axios.get(`/media_sources/${sourceId}/thumbnail/size/1?identifier=${identifier}`)
    },
    replaceDrs(context, options) {
      const { assetId, identifier, sourceType, sourceId, onProgress } = options
      const formData = new FormData()
      formData.append('replace-media-field', identifier)
      formData.append('sourceType', sourceType)
      formData.append('sourceId', sourceId)
      return axios.post(`/assets/${assetId}/representation`, formData, {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          onProgress && onProgress(identifier, percentCompleted)
        }
      })
    },

    clearState(context) {
      context.commit('RESET_STATE')
    },
    moveUploadToBackground(context) {
      context.commit('SET_BACKGROUND')
    }
  },
  getters: {
    isUploading(state) {
      return state.progress === 'uploading'
    },
    isFinished(state) {
      return state.progress === 'finished'
    }
  }
}
export default uploads
